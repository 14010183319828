import color from "../resource/color";

export const STATUS_COLOR_MAPPING: any = {
  ACTIVE: color["success"],
  INACTIVE: color["placeholder"],
  HOLD: color["warning"],
};
export const STATUS_COLOR_APPROVE: any = {
  WAIT_APPROVE: color["warning"],
  APPROVED: color["success"],
  REJECTED: color["error"],
};