import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import Text from "../../components/Text/Text";
import { Col, Divider, Form, Row, Spin } from "antd";
import { defaultPropsForm } from "../../utility/DefaultProps";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { useLocalStorage } from "../../hook/useLocalStorage";
import { useSetRecoilState } from "recoil";
import { profileAtom } from "../../store/ProfileAtom";
import { roleAtom } from "../../store/RoleAtom";
import { useNavigate } from "react-router-dom";
import { AuthDatasource } from "../../datasource/AuthDatasource";
import { useEffectOnce } from "react-use";
import { redirectByRole } from "../../utility/func/RedirectByPermission";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import axios from "axios";
import color from "../../resource/color";
import icon from "../../resource/icon";
import Swal from "sweetalert2";
import MicrosoftLogin from "react-microsoft-login";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { FormMaintenance } from "../MaintenancePage/MaintenancePage";
import { MaintenanceSystem, MaintenanceSystem_INIT } from "../../entities/MaintenanceSystem";
import moment from "moment";
import { MaintenanceDataSource } from "../../datasource/MaintenanceDatasource";
import { convertBuddhistYear } from "../../utility/Formatter";

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const CardStyled = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  .microsoft-login {
    border-radius: 4px;
    height: 40px;
  }
`;
const FooterLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;
interface GooglePayload {
  email: string;
  exp: number;
  name: string;
  picture: string;
}

function RightContainer({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [, setPersistedProfile] = useLocalStorage("profile", []);
  const [, setToken] = useLocalStorage("token", []);
  const [showPass, setShowPass] = useState<boolean>(false);
  const setProfile = useSetRecoilState(profileAtom);
  const setRole = useSetRecoilState(roleAtom);
  const [credentials, setCredentials] = React.useState<TokenResponse | null>(null);
  const navigate = useNavigate();
  const [dataMA, setDataMA] = useState<MaintenanceSystem>(MaintenanceSystem_INIT);
  const dateNow = moment(Date.now());
  const [checkTime, setCheckTime] = useState(false);

  const authHandler = useCallback(
    async (err: any, data: any) => {
      setLoading(true);
      try {
        await AuthDatasource.login(data.account.username).then((res: any) => {
          if (res.accessToken) {
            setPersistedProfile({
              ...res.data,
              roleId: res.rolePermission.roleId,
            });
            setRole({
              ...res.rolePermission,
            });
            setProfile({
              ...res.data,
              roleId: res.rolePermission.roleId,
            });
            setToken(res.accessToken);
            navigate(`${redirectByRole(res.rolePermission.menus)}`);
          } else {
            setLoading(false);
            return navigate("ErrorLoginPage");
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [setPersistedProfile, setToken, setProfile, setRole, navigate, setLoading],
  );

  const loginGoogle = useGoogleLogin({
    onSuccess: async (credentials) => {
      try {
        setCredentials(credentials);
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (credentials?.access_token) {
      const getProfile = async () => {
        try {
          setLoading(true);
          const result = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${credentials.access_token}`,
          );
          if (result.data) {
            await AuthDatasource.login(result.data.email).then((res: any) => {
              if (res.accessToken) {
                setPersistedProfile({
                  ...res.data,
                  roleId: res.rolePermission.roleId,
                });
                setToken(res.accessToken);
                setRole({
                  ...res.rolePermission,
                });
                setProfile({
                  ...res.data,
                  roleId: res.rolePermission.roleId,
                });
              } else {
                setLoading(false);
                if (res.userMessage === "user นี้ไม่มีในระบบ") {
                  setLoading(false);
                  Swal.fire({
                    icon: "warning",
                    title: `ไม่พบบัญชีผู้ใช้ในระบบ`,
                    showConfirmButton: false,
                  });
                  return;
                }
                return navigate("ErrorLoginPage");
              }
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getProfile();
    }
  }, [credentials, setPersistedProfile, setToken, setProfile, setRole, navigate, setLoading]);

  useEffectOnce(() => {
    setProfile(null);
    setRole(null);
  });
  const onFinish = async (values: { email: string; password: string }) => {
    try {
      const result = await AuthDatasource.loginWithEmail({
        email: values.email,
        password: values.password,
      });
      if (result && result.success) {
        if (
          result?.developerMessage === "Please set a new password" &&
          !result?.responseData.isPassword
        ) {
          navigate(`/setting-password?&token=${result.responseData.accessToken}`);
          return;
        }
        const res = result.responseData;
        localStorage.setItem("profile", res.data);
        localStorage.setItem("company", JSON.stringify(res.company));
        localStorage.setItem("isLoginWithEmail", "true");
        setPersistedProfile({
          ...res.data,
          roleId: res.rolePermission.roleId,
        });
        setRole({
          ...res.rolePermission,
        });
        setProfile({
          ...res.data,
          roleId: res.rolePermission.roleId,
        });

        setToken(res.accessToken);
      } else {
        if (result?.userMessage === "user นี้ไม่มีสิทธิ์เข้าใช้งาน กรุณาติดต่อเจ้าหน้าที่") {
          setLoading(false);
          Swal.fire({
            icon: "warning",
            title: `กรุณาตั้งค่ารหัสผ่านก่อนเข้าใช้งานระบบ`,
            text: "กรุณาตรวจสอบอีเมลของท่าน",
            timer: 2000,
            showConfirmButton: false,
          });
          return;
        }
        if (result?.userMessage === "user นี้ไม่มีในระบบ") {
          setLoading(false);
          Swal.fire({
            icon: "warning",
            title: `ไม่พบบัญชีผู้ใช้ในระบบ`,
            timer: 2000,
            showConfirmButton: false,
          });
          return;
        }
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "เข้าสู่ระบบไม่สำเร็จ",
        text: "กรุณาตรวจสอบอีเมลหรือรหัสผ่าน",
        timer: 2000,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const onForgotPassword = () => {
    navigate("/reset-password/add-mail");
  };

  useEffect(() => {
    checkMaintenance();
  }, []);
  const checkMaintenance = () => {
    MaintenanceDataSource.getMaintenance("BO").then((res) => {
      if (res.responseData) {
        setCheckTime(
          checkTimeMaintenance(
            moment(res.responseData.dateStart),
            moment(res.responseData.dateEnd),
          ),
        );
        res.responseData.textDate =
          "วันที่ " +
          convertBuddhistYear.toBuddhistYear(
            moment(res.responseData.dateStart),
            "DD MMMM YYYY ช่วงเวลา HH:mm - ",
          ) +
          moment(res.responseData.dateEnd).format("HH:mm น.");
        setDataMA(res.responseData);
      }
    });
  };

  const checkTimeMaintenance = (startDate: any, endDate: any) => {
    return dateNow.isBetween(startDate, endDate, "milliseconds");
  };

  const handleInputChange = (event) => {
    const charCode = event.which || event.keyCode;
    if ((charCode >= 0x0e01 && charCode <= 0x0e5b) || charCode === 0x0020) {
      event.preventDefault();
    }
  };
  return (
    <Container>
      {checkTime ? (
        <FormMaintenance data={dataMA} />
      ) : (
        <CardStyled>
          <ReactSVG
            onError={(error) => console.log(error)}
            src={`${process.env.PUBLIC_URL}/logo_sellcoda.svg`}
          />
          <Text
            fontSize={24}
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
            fontWeight={700}
          >
            เข้าสู่ระบบใช้งาน
          </Text>
          <Form
            {...defaultPropsForm}
            style={{
              width: "100%",
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label='อีเมล'
              name='email'
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกอีเมล",
                },
                {
                  type: "email",
                  message: "รูปแบบอีเมลไม่ถูกต้อง",
                },
              ]}
            >
              <Input placeholder='ระบุอีเมล' onKeyPress={handleInputChange} />
            </Form.Item>
            <Form.Item
              label='รหัสผ่าน'
              name='password'
              rules={[
                {
                  required: true,
                  message: "กรุณากรอกรหัสผ่าน",
                },
              ]}
            >
              <Input
                 onKeyPress={handleInputChange}
                type={showPass ? "text" : "password"}
                placeholder='ระบุรหัสผ่าน'
                suffix={
                  showPass ? (
                    <EyeInvisibleOutlined
                      onClick={() => {
                        setShowPass(false);
                      }}
                    />
                  ) : (
                    <EyeOutlined
                      onClick={() => {
                        setShowPass(true);
                      }}
                    />
                  )
                }
              />
            </Form.Item>
            <Row
              style={{
                width: "100%",
                justifyContent: "flex-end",
                position: "relative",
                top: -12,
              }}
            >
              <Text color='primary' onClick={onForgotPassword} fontFamily='Sarabun' fontSize={12}>
                ลืมรหัสผ่าน?
              </Text>
            </Row>

            <Button
              type='primary'
              htmlType='submit'
              title='เข้าสู่ระบบ'
              style={{
                width: "100%",
                marginTop: 16,
                marginBottom: 16,
              }}
            />
          </Form>
          <Divider style={{ width: "100%", marginTop: 16 }} />
          <Text fontFamily='Sarabun' fontSize={14}>
            หรือเข้าสู่ระบบผ่าน
          </Text>

          <FooterLogin>
            <div
              style={{
                width: "100%",
              }}
            >
              <MicrosoftLogin
                clientId='87575c83-d0d9-4544-93e5-7cd61636b45c'
                authCallback={authHandler}
                buttonTheme='dark'
                useLocalStorageCache={true}
                className='microsoft-login'
                redirectUri={`${window.origin}/`}
              >
                <Button
                  title='Microsoft'
                  typeButton='black'
                  icon={
                    <img
                      src={icon.iconMicrosoft}
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 8,
                      }}
                    />
                  }
                />
              </MicrosoftLogin>
            </div>

            <Button
              onClick={() => loginGoogle()}
              color='#4066CF'
              title='Google'
              textStyle={{
                fontFamily: "Helvetica",
                fontWeight: 400,
                lineHeight: 1,
              }}
              icon={
                <div
                  style={{
                    backgroundColor: color.white,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 1.5,
                    marginRight: 8,
                  }}
                >
                  <img
                    src={icon.googleLogo}
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </div>
              }
              level={5}
            />
          </FooterLogin>
        </CardStyled>
      )}
    </Container>
  );
}

export default RightContainer;
