import { BarChartOutlined, SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Table, Tag, Form, Tabs, Switch } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { CardContainer } from "../../../components/Card/CardContainer";
import { RangePicker } from "../../../components/DatePicker/DatePicker";
import Input from "../../../components/Input/Input";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import moment from "moment";
import { color } from "../../../resource";
import { useNavigate } from "react-router-dom";

type FixedType = "left" | "right" | boolean;

export const IndexCampaignPoint: React.FC = () => {
  const navigate = useNavigate();

  const dataMock = [
    {
      name: "จัดใหญ่ไฟวิบวับ",
      code: "CP000001",
      period: "01/08/2024 - 31/08/2024",
      pointProgram: 2,
      countShop: 10,
      updateDate: "29/08/2024",
      updateBy: "รชยา ช่างภักดี",
      status: true,
    },
    {
      name: "จัดใหญ่ไฟวิบวับ",
      code: "CP000002",
      period: "01/08/2024 - 31/08/2024",
      pointProgram: 2,
      countShop: 10,
      updateDate: "29/08/2024",
      updateBy: "รชยา ช่างภักดี",
      status: true,
    },
  ];

  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col span={6}>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };
  const tabsItems = [
    {
      label: `ทั้งหมด (${0})`,
      key: "ALL",
    },
    {
      label: `เปิดใช้งาน (${0})`,
      key: "true",
    },
    {
      label: `ปิดใช้งาน (${0})`,
      key: "false",
    },
  ];

  const PageTitle = () => {
    return (
      <Row align='middle' gutter={16}>
        <Col className='gutter-row' span={10}>
          <div>
            <span
              className='card-label font-weight-bolder text-dark'
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              แคมเปญพอยท์
            </span>
          </div>
        </Col>

        <Col span={5}>
          <Input
            placeholder='รหัส/ชื่อแคมเปญ'
            suffix={<SearchOutlined />}
            //defaultValue={filter.searchText}
            // onPressEnter={(e) => {
            //   const value = (e.target as HTMLTextAreaElement).value;
            //   setFilter({
            //     ...filter,
            //     page: 1,
            //     searchText: value,
            //   });
            // }}
          />
        </Col>
        <Col span={5}>
          <RangePicker
            enablePast
            allowEmpty={[true, true]}
            // value={!filter.startDate ? undefined : [dayjs(filter.startDate), dayjs(filter.endDate)]}
            // onChange={(date) => {
            //   if (date) {
            //     setFilter({
            //       ...filter,
            //       page: 1,
            //       startDate: moment(date[0]?.$d).format(),
            //       endDate: moment(date[1]?.$d).format(),
            //     });
            //   } else {
            //     setFilter({
            //       ...filter,
            //       page: 1,
            //       startDate: "",
            //       endDate: "",
            //     });
            //   }
            // }}
          />
        </Col>
        <Col className='gutter-row' xl={4} sm={6}>
          <Button
            type='primary'
            title='+ สร้างแคมเปญ'
            height={40}
            onClick={() => navigate("/crm/createCampaignPoint")}
          />
        </Col>
      </Row>
    );
  };
  const columns = [
    {
      title: "ชื่อแคมเปญพอยท์",
      dataIndex: "name",
      key: "name",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{row.name}</Text>
              <Text level={6} color='Text3'>
                {row.code}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "ระยะเวลา",
      dataIndex: "period",
      key: "period",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value}</Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "พอยท์โปรแกรม",
      dataIndex: "pointProgram",
      key: "pointProgram",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value}</Text>
              <Text level={6} color='Text3'>
                โปรแกรม
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "จำนวนร้านค้า",
      dataIndex: "countShop",
      key: "countShop",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value}</Text>
              <Text level={6} color='Text3'>
                ร้านค้า
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "อัปเดทโดย",
      dataIndex: "updateDate",
      key: "updateDate",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value}</Text>
              <Text level={6} color='Text3'>
                {row.updateBy}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Switch checked={value} />
              {/* {!moment(row.endDate).isBefore(moment()) ? (
                <Text level={6} color={value ? "success" : "error"}>
                  {value ? "เปิดการใช้งาน" : "ปิดการใช้งาน"}
                </Text>
              ) : (
                <Text level={6} color='Text3'>
                  หมดอายุ
                </Text>
              )} */}
            </>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      fixed: "right" as FixedType | undefined,
      width: "15%",

      render: (value: any, row: any, index: number) => {
        const isExpired = moment(row.endDate).isBefore(moment());
        return {
          children: (
            <>
              <Row>
                <ActionBtn
                  //onClick={() => navigate("/PromotionPage/promotion/detail/" + row.promotionId)}
                  icon={<UnorderedListOutlined />}
                />
                <ActionBtn
                  //onClick={() => navigate("/PromotionPage/promotion/detail/" + row.promotionId)}
                  icon={<BarChartOutlined />}
                />
              </Row>
            </>
          ),
        };
      },
    },
  ];
  return (
    <CardContainer>
      <PageTitle />
      <br />
      <Tabs items={tabsItems} />
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={dataMock || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          current: 1,
          total: 10,
          //onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
      />
    </CardContainer>
  );
};

export default IndexCampaignPoint;
