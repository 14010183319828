import { AxiosResponse } from "axios";
import { BASE_URL, NAV_URL, httpClient } from "../config/develop-config";
import { payloadProductBrand } from "../entities/ProductBrandEntity";
import { payloadMaster, payloadMasterCategory, payloadUpdateProductGroup } from "../entities/MasterDataEntity";

const baseUrl = `${BASE_URL}/master`;

const getProductList = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/product`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductGroup = async (company: string) => {
  return await httpClient
    .get(`${baseUrl}/product/product-group/${company}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductCategory = async (company: string) => {
  return await httpClient
    .get(`${baseUrl}/product-category`, { params: { company } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductBrand = async (company: string) => {
  return await httpClient
    .get(`${baseUrl}/product-brand`, { params: { company } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductBrandEx = async (payload: payloadProductBrand) => {
  return await httpClient
    .get(`${baseUrl}/product-brand/ex`, { params: payload })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductBrandById = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/product-brand/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const postProductBrand = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product-brand/create-product-brand`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const patchProductBrand = async (data: FormData) => {
  return await httpClient
    .patch(`${baseUrl}/product-brand/update-product-brand`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductDetail = async (productId: number) => {
  return await httpClient
    .get(`${baseUrl}/product/product-by-id`, { params: { productId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updateProduct = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product/update-product`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const syncProduct = async (data: { company: string }) => {
  return await httpClient
    .post(`${NAV_URL}/product`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductUnit = async (company: string, itemNo: string) => {
  return await httpClient
    .get(`${NAV_URL}/uom-nav?company=${company}&itemNo=${itemNo}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const createPriceListCorporate = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product/create-product`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const updatePriceListCorporate = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product/update-product-ex`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const importProduct = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product/import-products`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const importProductFreebies = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product-freebies/import-products`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
//master data
const getMasterProductGroup = async (payload: payloadMaster) => {
  return await httpClient
    .get(`${baseUrl}/product-group`, { params: payload })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const postProductGroup = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/product-group`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const patchProductGroup = async (data: payloadUpdateProductGroup) => {
  return await httpClient
    .patch(`${baseUrl}/product-group/` + data.id, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getProductGroupById = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/product-group/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getMasterProductCate = async (payload: payloadMasterCategory) => {
  return await httpClient
    .get(`${baseUrl}/product-category/all`, { params: payload })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const postMasterProductCate = async (data: FormData) => {
  return await httpClient
    .post(`${baseUrl}/product-category`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getMasterProductCateById = async (id: string) => {
  return await httpClient
    .get(`${baseUrl}/product-category/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const patchMasterProductCate = async (id: string, data: FormData) => {
  return await httpClient
    .patch(`${baseUrl}/product-category/${id}`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
export {
  getProductList,
  getProductGroup,
  getProductCategory,
  getProductBrand,
  getProductDetail,
  updateProduct,
  syncProduct,
  getProductUnit,
  createPriceListCorporate,
  updatePriceListCorporate,
  getProductBrandEx,
  getProductBrandById,
  postProductBrand,
  patchProductBrand,
  importProduct,
  importProductFreebies,
  getMasterProductGroup,
  postProductGroup,
  getProductGroupById,
  patchProductGroup,
  getMasterProductCate,
  postMasterProductCate,
  getMasterProductCateById,
  patchMasterProductCate,
};
