import React from "react";
import "./App.less";
import WebRoutes from "./WebRoutes";
import "antd/dist/antd.less";
import { RecoilRoot } from "recoil";
import locale_th from "antd/lib/locale-provider/th_TH";
import { ConfigProvider } from "antd";
import BuddhaEra from "dayjs/plugin/buddhistEra";
import dayjs from "dayjs";
import { QueryClient, QueryClientProvider } from "react-query";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/th";
import { GoogleOAuthProvider } from "@react-oauth/google";
dayjs.extend(BuddhaEra);
dayjs.extend(isBetween);
const googleClientId = "578439500366-b1e1acsc462351kkvvnu4d1tl2e3qvc5.apps.googleusercontent.com";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnReconnect: true,
      },
    },
  });

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={locale_th}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <WebRoutes />
          </GoogleOAuthProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
