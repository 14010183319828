import React, { useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  matchRoutes,
  useMatches,
  useParams,
} from "react-router-dom";
import Layouts from "./components/Layout/Layout";
import { ModalMaintence } from "./components/Modal/ModalMaintenance";
import { MaintenanceSystem, MaintenanceSystem_INIT } from "./entities/MaintenanceSystem";
import { MaintenanceDataSource } from "./datasource/MaintenanceDatasource";
import { convertBuddhistYear } from "./utility/Formatter";
import moment from "moment";
const regexPatterns = [/SyncCustomer$/];
const checkPath = (path: string, caseList: RegExp[]) => {
  return caseList.some((pattern) => pattern.test(path));
};

const ProtectRoute = ({ isAuth, children }: { isAuth: boolean; children?: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const isMatch = checkPath(location, regexPatterns);
  const [showModalMaintenance, setShowModalMaintenance] = useState<boolean>(true);
  const [start, setStart] = useState<any>();
  const [end, setEnd] = useState<any>();
  const [checkTime, setCheckTime] = useState(false);
  const [dataMaintenance, setDataMaintenance] = useState<MaintenanceSystem>(MaintenanceSystem_INIT);
  const dateNow = moment(Date.now());
  const userStaffId = JSON.parse(localStorage.getItem("profile")!);

  useEffect(() => {
    const getMaintenance = async () => {
      const value = await localStorage.getItem("Maintenance");
      await MaintenanceDataSource.getMaintenance("BO", userStaffId.userStaffId)
        .then((res) => {
          if (res.responseData !== null) {
            setStart(
              convertBuddhistYear.toBuddhistYear(
                moment(res.responseData.dateStart),
                "DD MMMM YYYY ช่วงเวลา HH:mm ",
              ),
            );
            setEnd(
              convertBuddhistYear.toBuddhistYear(
                moment(res.responseData.dateEnd),
                "DD MMMM YYYY ช่วงเวลา HH:mm  ",
              ),
            );
            setCheckTime(
              checkTimeMaintance(
                moment(res.responseData.dateNotiStart),
                moment(res.responseData.dateNotiEnd),
              ),
            );
            if (value === "read") {
              setDataMaintenance(res.responseData);
              setShowModalMaintenance(false);
            } else {
              setDataMaintenance(res.responseData);
              setShowModalMaintenance(res.responseData.id ? true : false);
              res.responseData.textDate =
                "วันที่ " +
                convertBuddhistYear.toBuddhistYear(
                  moment(res.responseData.dateStart),
                  "DD MMMM YYYY ช่วงเวลา HH:mm - ",
                ) +
                moment(res.responseData.dateEnd).format("HH:mm น.");
            }
          }
        })
        .catch((err) => console.log(err));
    };
    getMaintenance();
  }, [location]);

  const checkTimeMaintance = (startDate: any, endDate: any) => {
    return dateNow.isBetween(startDate, endDate, "milliseconds");
  };

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, [navigate, isAuth]);
  if (isMatch) {
    return <Outlet />;
  }

  return children ? (
    children
  ) : (
    <Layouts>
      <Outlet />
      {checkTime && (
        <ModalMaintence
          show={showModalMaintenance}
          onClose={async () => {
            await localStorage.setItem("Maintenance", "read");
            await localStorage.setItem("dateStart", start);
            await localStorage.setItem("dateEnd", end);
            setShowModalMaintenance(!showModalMaintenance);
          }}
          data={dataMaintenance}
        />
      )}
    </Layouts>
  );
};

export default ProtectRoute;
