import React, { useEffect, useMemo, useRef, useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { useForm, useWatch } from "antd/lib/form/Form";
import { Col, Form, message, Row, Upload, Button, Checkbox, Divider, Image } from "antd";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import StepAntd from "../../../components/StepAntd/StepAntd";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { color } from "../../../resource";
import ImgCrop from "../../../components/ImgCrop/ImgCrop";
import styled from "styled-components";
import Text from "../../../components/Text/Text";
import { ImageWithDeleteButton, UploadIcon } from "../../../components/Image/Image";
import Input from "../../../components/Input/Input";
import DatePicker, { TimePicker } from "../../../components/DatePicker/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import ReactQuill, { Quill } from "react-quill";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import image from "../../../resource/image";
import icon from "../../../resource/icon";
import { getBase64 } from "../../../utility/uploadHelper";
import { RcFile } from "antd/lib/upload";
import Buttons from "../../../components/Button/Button";

const UploadHorizontal = styled(Upload)`
  .ant-upload,
  .ant-upload-list-picture-card-container,
  .ant-upload-picture-card-wrapper,
  .ant-upload-list-picture-card .ant-upload-list-item {
    height: 130px;
    width: 220px;
  }
`;
const UploadArea = styled.div`
  background: ${color.background1};
  border: 1px dashed;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

const imgCropProps = {
  modalTitle: "ปรับขนาดรูปภาพ",
  modalOk: "ยืนยัน",
  modalCancel: "ยกเลิก",
};

const QuillContainer = styled.div`
  .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0 !important;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 4px 4px !important;
  }
  .ql-editor {
    max-height: 80vh;
    overflow: auto;
  }
`;

const MemoArea = styled.div`
  width: 100%;
  background: ${color.background1};
  border: 1px solid ${color.background2};
  border-radius: 8px;

  display: flex;
  align-items: center;
  padding: 16px;
`;

const MemoAreaItem = styled.div`
  padding: 0px 8px;
  display: flex;
  align-items: center;
`;

export const CreateCampaignPoint: React.FC = () => {
  const [form1] = useForm();
  const [current, setCurrent] = useState(0);

  const quillRef = useRef<any>(null);

  const [showVideoModal, setVideoModal] = useState(false);
  const [vidIndex, setVidIndex] = useState();

  const campaignName = useWatch("campaignName", form1);
  const startDate = useWatch("startDate", form1) || "";
  const endDate = useWatch("endDate", form1) || "";
  const [imageUrl, setImgUrl] = useState<string>();
  const [file, setFile] = useState<any>();

  useEffect(() => {
    form1.setFieldValue("isRebate", true);
  }, []);

  const videoHandler = useMemo(() => {
    return () => {
      const quill = quillRef?.current?.getEditor();
      const range = quill.getSelection();
      setVidIndex(range.index);
      setVideoModal(true);
    };
  }, []);

  const modules = {
    toolbar: {
      handlers: {
        video: videoHandler,
      },
      container: [
        ["bold", "italic", "link", "image", "video"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: "" }, { align: "center" }, { align: "right" }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const StepOne = () => {
    return (
      <Row justify='space-between' gutter={8}>
        <Col span={17}>
          <Form form={form1} layout='vertical'>
            <Text level={5} fontWeight={700}>
              รายละเอียดการแคมเปญ
            </Text>
            <Row justify='start' gutter={8}>
              <Form.Item noStyle name='horizontalImage' valuePropName='file'>
                <Col span={6}>
                  <ImgCrop aspect={16 / 9} {...imgCropProps}>
                    <UploadHorizontal
                      listType='picture-card'
                      maxCount={1}
                      beforeUpload={(file) => {
                        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
                        if (!isJpgOrPng) {
                          message.error("You can only upload JPG/PNG file!");
                          return true;
                        }
                        setFile(file);
                        getBase64(file as RcFile, (url) => {
                          setImgUrl(url);
                        });
                      }}
                      customRequest={() => {
                        console.log("customRequest");
                      }}
                      onChange={({ file }: any) => {
                        return "success";
                      }}
                      onRemove={() => {
                        setFile(undefined);
                      }}
                      showUploadList={false}
                      disabled={!!file || !!imageUrl}
                    >
                      {!file && !imageUrl ? (
                        <>
                          <UploadArea
                            style={{
                              width: "220px",
                              height: "130px",
                              //borderColor: imgPromotion ? color.error : color.Text3,
                            }}
                          >
                            {UploadIcon}
                          </UploadArea>
                        </>
                      ) : (
                        <ImageWithDeleteButton
                          width='220px'
                          height='130px'
                          src={imageUrl}
                          handleDelete={() => {
                            setFile(undefined);
                            setImgUrl(undefined);
                          }}
                        />
                      )}
                    </UploadHorizontal>
                  </ImgCrop>
                </Col>
                <Col>
                  <Text level={6} color='Text3'>
                    รูปภาพประกอบแคมเปญ
                    <br />
                    รูปแบบไฟล์ภาพ
                    <br />
                    JPG / PNG
                    <br />
                    ขนาดภาพ
                    <br />
                    1200 x 675 px (16:9)
                  </Text>
                </Col>
              </Form.Item>
            </Row>
            <Row gutter={8} justify='space-between'>
              <Col span={12}>
                <Form.Item
                  name='campaignCode'
                  label='รหัสแคมเปญ'
                  rules={[
                    {
                      required: true,
                      message: "*โปรดระบุรหัสแคมเปญ",
                    },
                  ]}
                >
                  <Input placeholder='ระบุรหัสแคมเปญ' autoComplete='off' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='campaignName'
                  label='ชื่อแคมเปญ'
                  rules={[
                    {
                      required: true,
                      message: "*โปรดระบุชื่อแคมเปญ",
                    },
                  ]}
                >
                  <Input placeholder='ระบุชื่อแคมเปญ' autoComplete='off' />
                </Form.Item>
              </Col>
            </Row>
            <Row align='middle' gutter={16}>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='startDate'
                      label='วันที่เริ่มแคมเปญ'
                      rules={[
                        {
                          required: true,
                          message: "*โปรดเลือกวันที่เริ่มโปรโมชัน",
                        },
                      ]}
                    >
                      <DatePicker style={{ width: "100%" }} enablePast />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='startTime'
                      label='เวลาเริ่มต้นแคมเปญ'
                      initialValue={dayjs("00:00", "HH:mm")}
                    >
                      <TimePicker allowClear={false} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='endDate'
                      label='วันที่สิ้นสุดแคมเปญ'
                      rules={[
                        {
                          required: true,
                          message: "*โปรดเลือกวันที่สิ้นสุดโปรโมชัน",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={(current: Dayjs) => {
                          const startDate = form1.getFieldValue("startDate");
                          return current && current.isBefore(dayjs(startDate));
                        }}
                        enablePast
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='endTime'
                      label='เวลาสิ้นสุดแคมเปญ'
                      initialValue={dayjs("23:59", "HH:mm")}
                    >
                      <TimePicker
                        allowClear={false}
                        disabledTime={(now) => {
                          const startDate = form1.getFieldValue("startDate") as Dayjs;
                          const endDate = form1.getFieldValue("endDate") as Dayjs;
                          const startTime = form1.getFieldValue("startTime") as Dayjs;
                          const isSameDay =
                            startDate && endDate && startDate?.isSame(endDate, "year");
                          if (!isSameDay) return {};

                          const hour = startTime.hour();
                          const minute = startTime.minute();
                          const hours: number[] = [];
                          const minutes: number[] = [];
                          for (let i = 0; i < hour; i++) {
                            hours.push(i);
                          }
                          for (let i = 0; i <= minute; i++) {
                            minutes.push(i);
                          }
                          return {
                            disabledHours: () => hours,
                            disabledMinutes: (selectedHour: number) =>
                              selectedHour === hour ? minutes : [],
                          };
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Form.Item name='condition' label='กติกาและเงื่อนไขแคมเปญ' required>
              <QuillContainer>
                <ReactQuill
                  ref={quillRef}
                  modules={modules}
                  //onChange={(c) => setContent(c)}
                  //value={content}
                />
              </QuillContainer>
            </Form.Item>
            <Text level={5} fontWeight={700}>
              <img src={icon.iconTarget} style={{ paddingBottom: "5px" }} /> เป้าหมายแคมเปญ
            </Text>
            <Row justify='start' gutter={8}>
              <Form.Item name='isRebate' valuePropName='checked'>
                <Checkbox>
                  <Text level={5}>เพื่อ Rebate</Text>
                </Checkbox>
              </Form.Item>
            </Row>
            <Divider />
            <Text level={5} fontWeight={700}>
              ไฟล์เอกสารอ้างอิง
            </Text>
            <Row>
              <Col span={12}>
                <MemoArea>
                  <MemoAreaItem style={{ width: 124 }}>
                    <Form.Item noStyle name='memoFile' valuePropName='fileList'>
                      <Upload
                        beforeUpload={(file) => {
                          const isPDF = file.type === "application/pdf";
                          if (!isPDF) {
                            message.error(`อัปโหลดเฉพาะไฟล์ pdf เท่านั้น`);
                            return false;
                          }
                          return isPDF || Upload.LIST_IGNORE;
                        }}
                        customRequest={({ file, onSuccess }) => {
                          if (onSuccess) {
                            onSuccess(file);
                          }
                        }}
                        onChange={({ file }: any) => {
                          if (file.status === "uploading") {
                            //setFileMemo(file);
                            file.status = "done";
                          }
                          if (file.status === "done") {
                            //setFileMemo(file);
                          }
                          return "success";
                        }}
                        onRemove={() => {
                          //setFileMemo(undefined);
                        }}
                        maxCount={1}
                        showUploadList={false}
                      >
                        <Button type='primary' icon={<UploadOutlined />}>
                          เลือกไฟล์
                        </Button>
                      </Upload>
                    </Form.Item>
                  </MemoAreaItem>
                  <MemoAreaItem style={{ width: "calc(100% - 156px)" }}>
                    <svg
                      width='24'
                      height='31'
                      viewBox='0 0 24 31'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M20.0596 0.0175781L24 3.84855V5.85091V27.5176C24 28.8983 22.8487 30.0176 21.4286 30.0176H2.57143C1.15127 30.0176 0 28.8983 0 27.5176V2.51758C0 1.13687 1.15127 0.0175781 2.57143 0.0175781H18H20.0596ZM18 1.18424H2.57143C1.85609 1.18424 1.26867 1.71671 1.2056 2.39622L1.2 2.51758V27.5176C1.2 28.213 1.74768 28.7842 2.4466 28.8455L2.57143 28.8509H21.4286C22.1439 28.8509 22.7313 28.3184 22.7944 27.6389L22.8 27.5176V5.85091H18V1.18424ZM22.8 4.68424L22.8 4.33174L19.5626 1.18424H19.2V4.68424H22.8Z'
                        fill='#464E5F'
                      />
                      <path d='M4 15.6846H5.83333V17.5179H4V15.6846Z' fill='#464E5F' />
                      <path
                        d='M6.75 12.0176H8.98014C9.41286 12.0176 9.74294 12.0895 9.97039 12.2334C10.2034 12.372 10.3587 12.5718 10.4364 12.833C10.5196 13.0888 10.5612 13.4246 10.5612 13.8403C10.5612 14.24 10.5224 14.5704 10.4447 14.8315C10.367 15.0873 10.2117 15.2925 9.97871 15.4471C9.74571 15.5963 9.40731 15.6709 8.9635 15.6709H7.80682V17.5176H6.75V12.0176ZM8.614 14.7916C8.89138 14.7916 9.08832 14.7676 9.20482 14.7196C9.32687 14.6663 9.40454 14.5784 9.43782 14.4558C9.47665 14.3279 9.49607 14.1227 9.49607 13.8403C9.49607 13.5578 9.47665 13.3553 9.43782 13.2327C9.40454 13.1048 9.32964 13.0169 9.21314 12.9689C9.09664 12.9209 8.90248 12.8969 8.63064 12.8969H7.80682V14.7916H8.614Z'
                        fill='#464E5F'
                      />
                      <path
                        d='M11.4471 12.0176H13.1696C13.8741 12.0176 14.3734 12.0949 14.6674 12.2494C14.967 12.3986 15.1529 12.6491 15.225 13.0009C15.3027 13.3473 15.3415 13.9362 15.3415 14.7676C15.3415 15.599 15.3027 16.1905 15.225 16.5423C15.1529 16.8887 14.967 17.1392 14.6674 17.2937C14.3734 17.443 13.8741 17.5176 13.1696 17.5176H11.4471V12.0176ZM13.1197 16.6382C13.5246 16.6382 13.7965 16.6089 13.9352 16.5503C14.0794 16.4917 14.1709 16.3451 14.2098 16.1106C14.2542 15.8761 14.2763 15.4284 14.2763 14.7676C14.2763 14.1067 14.2542 13.6591 14.2098 13.4246C14.1709 13.1901 14.0794 13.0435 13.9352 12.9849C13.7965 12.9263 13.5246 12.8969 13.1197 12.8969H12.5039V16.6382H13.1197Z'
                        fill='#464E5F'
                      />
                      <path
                        d='M16.3879 17.5176V12.0176H19.5833V12.8969H17.4447V14.4318H19.3337V15.3192H17.4447V17.5176H16.3879Z'
                        fill='#464E5F'
                      />
                    </svg>
                    &nbsp;
                    <Text
                      level={6}
                      color='Text3'

                      //style={{ cursor: fileMemo || fileMemoUrl ? "pointer" : "default" }}
                    >
                      โปรดเลือกไฟล์ .PDF
                    </Text>
                  </MemoAreaItem>
                  <MemoAreaItem
                    style={{
                      width: 32,
                      // visibility:
                      //   (fileMemo as any)?.originFileObj || fileMemoUrl ? "visible" : "hidden",
                    }}
                  >
                    <DeleteOutlined style={{ color: color.error, cursor: "pointer" }} />
                  </MemoAreaItem>
                </MemoArea>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={7}>
          <CardContainer style={{ backgroundColor: color.Disable }}>
            <Text level={5} fontWeight={700}>
              ตัวอย่างหน้า Application
            </Text>
            {imageUrl && (
              <img
                src={imageUrl || ""}
                height={170}
                width={304}
                style={{
                  position: "absolute",
                  marginTop: "27%",
                  marginLeft: "-47%",
                  overflowX: "hidden",
                  overflowY: "auto",
                  // borderRadius: "10px",
                }}
              />
            )}

            <img src={image.campaignDetail} width='100%' />
            <Text
              level={6}
              fontWeight={600}
              style={{ position: "absolute", top: "49%", left: "45px" }}
            >
              <img src={icon.iconMegaphone} style={{ width: "22px" }} />
              {"   "}
              {campaignName || `ชื่อแคมเปญ`}
            </Text>
            <div
              style={{
                width: "81%",
                height: "30px",
                background: "#FAFAFA",
                position: "absolute",
                top: "53%",
                left: "32px",
              }}
            >
              <Text
                fontWeight={600}
                level={6}
                style={{ position: "absolute", top: "22%", left: "15px" }}
              >
                ระยะเวลา : {startDate && `${dayjs(startDate).format("DD/MM/YYYY")} - `}
                {endDate && dayjs(endDate).format("DD/MM/YYYY")}
              </Text>
              {/* <Text
                color='primary'
                fontWeight={600}
                level={6}
                style={{ position: "absolute", top: "22%", left: "190px" }}
              >
                กติกาและเงื่อนไข
              </Text> */}
            </div>
          </CardContainer>
        </Col>
      </Row>
    );
  };

  const renderStep = () => {
    switch (current) {
      case 0: {
        return StepOne();
      }
      case 1: {
        return StepOne();
      }
    }
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={`รายการแคมเปญพอยท์`}
        showBack
        extra={
          <StepAntd
            current={current}
            items={[
              {
                title: "รายละเอียดแคมเปญ",
              },
              {
                title: "เพิ่มพอยท์โปรแกรม",
              },
            ]}
          />
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการแคมเปญพอยท์", path: "/crm/campaignPoint" },
              {
                text: "สร้างแคมเปญพอยท์",
                path: window.location.pathname,
              },
            ]}
          />
        }
      />
    );
  };
  const footer = () => {
    return (
      <Row justify='space-between' gutter={12}>
        <Col xl={3} sm={6}>
          {current > 0 && (
            <Buttons
              typeButton='primary-light'
              title='ย้อนกลับ'
              onClick={() => setCurrent(current - 1)}
            />
          )}
        </Col>
        <Col xl={15} sm={6}></Col>
        <Col xl={3} sm={6}>
          <Buttons
            typeButton='primary'
            title={current === 1 ? "บันทึก" : "ถัดไป"}
            onClick={() => {
              //nextStep();
            }}
          />
        </Col>
      </Row>
    );
  };
  return (
    <CardContainer>
      <PageTitle />
      {renderStep()}
      <br />
      {footer()}
    </CardContainer>
  );
};
